<template>
  <div>
    <NavBar></NavBar>
    <Breadcrumb></Breadcrumb>
    <div class="container">
      <div class="row mt-3">
        <div class="col-lg-3">
          <SideBar></SideBar>
        </div>
        <div class="col-lg-9 content">
          <div class="pages">
            <div v-if="pageDetailsList.data">
              <h1 class="main-title">{{ pageDetailsList.data.title }}</h1>
              <div
                v-html="pageDetailsList.data.description.replace('/files/', 'https://cdn.tavguvenlik.com/files/')"
              ></div>

              <!-- Image Gallery -->
              <div v-if="pageDetailsList.data.imageGalleries && pageDetailsList.data.imageGalleries.length" class="image-gallery">
                <div
                  v-for="(image, index) in pageDetailsList.data.imageGalleries"
                  :key="index"
                  class="gallery-item"
                  @click="openModal(index)"
                >
                  <img
                    :src="'https://cdn.tavguvenlik.com/uploads/' + VUE_APP_APP_KEY +'/static-pages/image-gallery/' + image.fileName"
                    :alt="pageDetailsList.data.title"
                  />
                </div>
              </div>

              <div v-if="pageDetailsList.data.slug === 'uzaktan-izleme-ve-alarm-takip-merkezi' || pageDetailsList.data.slug === 'elektronik-guvenlik-hizmetleri' || pageDetailsList.data.slug === 'entegre-guvenlik-cozumleri'">
                <AlarmForm></AlarmForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>

    <!-- Modal with Carousel -->
    <b-modal id="imageModal" ref="imageModal" size="xl" hide-footer hide-header>
      <div class="modal-header">
        <h5 class="modal-title">{{ pageDetailsList.data ? pageDetailsList.data.title : '' }}</h5>
        <button type="button" class="close" aria-label="Close" @click="closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <b-carousel
        id="carouselExampleIndicators"
        :interval="8000"
        controls
        indicators
        v-model="currentIndex"
      >
        <b-carousel-slide
          v-for="(image, index) in pageDetailsList.data && pageDetailsList.data.imageGalleries"
          :key="index"
          :img-src="'https://cdn.tavguvenlik.com/uploads/' + VUE_APP_APP_KEY +'/static-pages/image-gallery/' + image.fileName"
          :alt="image.title"
        >
        </b-carousel-slide>
      </b-carousel>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BCarousel, BCarouselSlide } from 'bootstrap-vue';
import ContentService from '@/services/ContentService.js';

import NavBar from "@/components/shared/NavBar.vue";
import Breadcrumb from "@/components/shared/Breadcrumb.vue";
import SideBar from "@/components/shared/SideBar.vue";
import AlarmForm from '@/components/AlarmForm.vue';
import Footer from "@/components/shared/Footer.vue";

export default {
  components: {
    NavBar,
    Breadcrumb,
    SideBar,
    AlarmForm,
    Footer,
    BModal,
    BCarousel,
    BCarouselSlide
  },
  data() {
    return {
      pageDetailsList: {},
      VUE_APP_APP_KEY: process.env.VUE_APP_APP_KEY,
      currentIndex: 0
    };
  },
  created() {
    ContentService.getPageDetail(this.$route.params.pageDetailSlug, this.$application, this.$t("languageCode"))
      .then((res) => (this.pageDetailsList = res.data))
      .catch((error) => console.log("There was an error:" + error));
  },
  methods: {
    openModal(index) {
      this.currentIndex = index; // Set the current index
      this.$nextTick(() => {
        this.$refs.imageModal.show(); // Show the modal
      });
    },
    closeModal() {
      this.$refs.imageModal.hide();
    }
  }
};
</script>

<style scoped>
.image-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 2rem;
    align-items: center;
    justify-content: flex-start;
}

.gallery-item {
  text-align: center;
    cursor: pointer;
    width: 240px;
    height: 160px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
}

.gallery-item img {
  width: auto;
  height: 100%;
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.gallery-item img:hover{
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
